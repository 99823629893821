import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LocalityKm, Packages} from '../../model/api-service.interface';
import {debounce} from 'lodash';
import {OrderService} from '../../services/site/order.service';
import {ConstantsService} from '../../services/constants.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit {
    telefon = '';
    siteEmail = '';
    siteName = '';
    enableViewStatus = false;
    enableViewPrice = false;

    constructor(private modalService: NgbModal, private route: ActivatedRoute,
                private router: Router, private readonly api: OrderService,
                private fb: FormBuilder) {
        this.onSubmitInternational = debounce(this.onSubmitInternational, 300);
        this.onSubmit = debounce(this.onSubmit, 300);
    }

    countiesCollecting: any;
    countiesDestination: any;
    localitiesCollecting: any;
    localitiesDestination: Array<LocalityKm>;
    countriesDestination: any;
    countriesCollecting: any;

    TotalRow: number;
    cost: string;
    verif: boolean;
    verifInter: boolean;
    continua: string;
    adauga: boolean;
    obiectDeMapat: any;
    adaugaInternational: boolean;
    defaultValue = 1;
    countriesDestinationStabil: any;
    packages = new FormGroup({});

    international = new FormGroup({
        countryCollecting: new FormControl(''),
        countryDestination: new FormControl(''),
    });

    packageInternational = new FormGroup({});
    destination = new FormGroup({
        collectingCounty: new FormControl(''),
        collectingLocality: new FormControl(''),
        deliveryCounty: new FormControl(''),
        deliveryLocality: new FormControl(''),
        insurancePackage: new FormControl(''),
        withCurrency: new FormControl(''),
        saturdayDelivery: new FormControl(''),
        verifyPackage: new FormControl('')
    });

    ngOnInit(): void {
        // angular.forEach(({a: 12, b: 34}, (value, key) => console.log('key: ' + key + ', value: ' + value)));
        this.telefon = ConstantsService.telefon;
        this.siteEmail = ConstantsService.email;
        this.siteName = ConstantsService.siteName;
        this.getCollectingCounties();
        this.getDestinationCounties();
        this.getDestinationCountRies();
        this.getCountryes();
    }

    itemInitRow(): FormGroup {
        return this.fb.group({
            Nr: 1,
            Pachet: [''],
            Lungime: [''],
            Latime: [''],
            Inaltime: [''],
            Greutate: [''],
        });
    }

    getCollectingCounties(): void {
        this.api.getCounties().subscribe((res) => this.countiesCollecting = res);
    }

    getDestinationCounties(): void {
        this.api.getCounties().subscribe((res) => this.countiesDestination = res);
    }

    getCollectingLocality(): void {
        this.api.getLocality(this.destination.controls.collectingCounty.value).subscribe((res) => this.localitiesCollecting = res);
    }

    getDestinationLocality(): void {
        this.api.getLocality(this.destination.controls.deliveryCounty.value).subscribe((res) => this.localitiesDestination = res);
    }

    getDestinationCountRies(): void {
        this.api.getcountRies().subscribe((res) => this.countriesDestinationStabil = res);
    }

    onCompleteCountyLocality(): void {
        const data = {
            collecting_county: this.destination.controls.collectingCounty.value === '' ? '' : this.destination.controls.collectingCounty.value,
            collecting_locality: this.destination.controls.collectingLocality.value === '' ? '' : this.destination.controls.collectingLocality.value,
            delivery_county: this.destination.controls.deliveryCounty.value === '' ? '' : this.destination.controls.deliveryCounty.value,
            delivery_locality: this.destination.controls.deliveryLocality.value === '' ? '' : this.destination.controls.deliveryLocality.value,
        };

        const itemRows = this.packages.controls.itemRows as FormArray;
        if (data.collecting_county !== '' && data.collecting_locality !== ''
            && data.delivery_county !== '' && data.delivery_locality !== '' && itemRows === undefined) {
            this.packages = this.fb.group({
                itemRows: this.fb.array([this.itemInitRow()]),
            });
            this.adauga = true;
        } else {
            this.cost = '<BR>';
            this.continua = '<BR>';
        }
    }

    onCompleteCountyLocalityInternation(): void {
        const dataInternational = {
            collecting_country: this.international.controls.countryCollecting.value === '' ? '' : this.international.controls.countryCollecting.value,
            destination_country: this.international.controls.countryDestination.value === '' ? '' : this.international.controls.countryDestination.value,
        };

        const itemRows = this.packageInternational.controls.itemRows as FormArray;
        if (dataInternational.destination_country !== '' && itemRows === undefined) {
            this.packageInternational = this.fb.group({
                itemRows: this.fb.array([this.itemInitRow()]),
            });
            this.adaugaInternational = true;
        } else {
            this.obiectDeMapat = [];
        }
    }

    onSubmitInternational(): void {
        this.verifInter = false;
        const myPacks: Packages[] = [];
        let itemRows = this.packageInternational.controls.itemRows as FormArray;
        if (!itemRows) {
            itemRows = new FormArray([]);
        }
        const costInternational = {
            collecting_country: this.international.controls.countryCollecting.value === '' ? '' : this.international.controls.countryCollecting.value,
            delivery_country: this.international.controls.countryDestination.value === '' ? '' : this.international.controls.countryDestination.value,
            userId: 28,
            withCurrency: true,
            packages: myPacks
        };
        for (let i = 0; i < itemRows.length; i++) {
            const item = itemRows.at(i) as FormGroup;
            myPacks.push({
                amount: item.controls.Nr.value === 0 ? '' : item.controls.Nr.value,
                type: item.controls.Pachet.value === 0 ? '' : item.controls.Pachet.value,
                length: item.controls.Lungime.value === 0 ? '' : item.controls.Lungime.value,
                width: item.controls.Latime.value === 0 ? '' : item.controls.Latime.value,
                height: item.controls.Inaltime.value === 0 ? '' : item.controls.Inaltime.value,
                weight: item.controls.Greutate.value === 0 ? '' : item.controls.Greutate.value,
            });
            if (costInternational.collecting_country !== '' && costInternational.delivery_country !== ''
                && item.controls.Nr.value !== '' && item.controls.Nr.value !== null && item.controls.Pachet.value !== ''
                && item.controls.Pachet.value !== null
                && item.controls.Lungime.value !== '' && item.controls.Lungime.value !== null &&
                item.controls.Latime.value !== '' && item.controls.Latime.value !== null &&
                item.controls.Inaltime.value !== '' && item.controls.Inaltime.value !== null
                && item.controls.Greutate.value !== '' && item.controls.Greutate.value !== null) {
                this.verifInter = true;
            }
        }

        if (this.verifInter) {
            this.api.postInternationalCost(costInternational).subscribe((res) => this.obiectDeMapat = res);
        } else {
            this.obiectDeMapat = [];
        }
    }

    getCountryes(): void {
        if (this.international.controls.countryCollecting.value === 'Romania'
            && (this.countriesDestination === undefined || this.countriesDestination.length < 2)) {
            this.api.getcountRies().subscribe((res) => this.countriesDestination = res);
            this.international.controls.countryDestination.setValue('');
        } else if (this.countriesDestination === undefined || this.countriesDestination.length > 1) {
            this.countriesDestination = ['Romania'];
            this.international.controls.countryDestination.setValue('');
        }
    }

    getItemField(nameField: any): boolean {
        if (nameField.value.Pachet === 'PLIC') {
            nameField.controls.Lungime.value = '1';
            nameField.controls.Latime.value = '1';
            nameField.controls.Inaltime.value = '1';
        } else {
            nameField.controls.Lungime.value = '';
            nameField.controls.Latime.value = '';
            nameField.controls.Inaltime.value = '';
        }

        return nameField.value.Pachet === 'PLIC';
    }

    onSubmit(): void {
        this.verif = false;
        const myPacks: Packages[] = [];
        const itemRows = this.packages.controls.itemRows as FormArray;
        const cost = {
            collectingCounty: this.destination.controls.collectingCounty.value,
            collectingLocality: this.destination.controls.collectingLocality.value,
            deliveryCounty: this.destination.controls.deliveryCounty.value,
            deliveryLocality: this.destination.controls.deliveryLocality.value,
            insurancePackage: null,
            withCurrency: true,
            saturdayDelivery: null,
            verifyPackage: null,
            repaymentType: '',
            packages: myPacks
        };
        for (let i = 0; i < itemRows.length; i++) {
            const item = itemRows.at(i) as FormGroup;
            myPacks.push({
                amount: item.controls.Nr.value === 0 ? '' : item.controls.Nr.value,
                type: item.controls.Pachet.value === 0 ? '' : item.controls.Pachet.value,
                length: item.controls.Lungime.value === 0 ? '' : item.controls.Lungime.value,
                width: item.controls.Latime.value === 0 ? '' : item.controls.Latime.value,
                height: item.controls.Inaltime.value === 0 ? '' : item.controls.Inaltime.value,
                weight: item.controls.Greutate.value === 0 ? '' : item.controls.Greutate.value,
            });
            if (cost.collectingCounty !== '' && cost.collectingLocality !== '' && cost.deliveryCounty !== '' && cost.deliveryLocality !== ''
                && item.controls.Nr.value !== '' && item.controls.Nr.value !== null && item.controls.Pachet.value !== ''
                && item.controls.Pachet.value !== null
                && item.controls.Lungime.value !== '' && item.controls.Lungime.value !== null &&
                item.controls.Latime.value !== '' && item.controls.Latime.value !== null &&
                item.controls.Inaltime.value !== '' && item.controls.Inaltime.value !== null
                && item.controls.Greutate.value !== '' && item.controls.Greutate.value !== null) {
                this.verif = true;
            }
        }
        if (this.verif) {
            this.api.postCost(cost).subscribe((res) => {
                this.cost = res.COST === undefined ? '<br>' : res.COST;
                this.continua = res.COST === undefined ? '<br>' : '<div class="alert alert-primary alert-pills">' +
                    '<span class="alert-content"></span>Continua</div>';
            });
        } else {
            this.cost = '<BR>';
            this.continua = '<BR>';
        }
    }

    addPackage(packages: FormGroup): void {
        const control = packages.controls.itemRows as FormArray;
        control.push(this.itemInitRow());
    }

    deleteRow(index: number, packageD: FormGroup): void {
        const control = packageD.controls.itemRows as FormArray;
        if (control != null) {
            this.TotalRow = control.value.length;
        }
        if (this.TotalRow > 1) {
            control.removeAt(index);
        }
    }

    goToPage(apiName: string): void {
        const loc = JSON.stringify(this.destination.controls.collectingLocality.value);
        const myPacks: Packages[] = [];
        const itemRows = this.packages.controls.itemRows as FormArray;
        // console.log(itemRows.length);
        const cost = {
            collectingCounty: this.destination.controls.collectingCounty.value,
            collectingLocality: this.destination.controls.collectingLocality.value,
            deliveryCounty: this.destination.controls.deliveryCounty.value,
            deliveryLocality: this.destination.controls.deliveryLocality.value,
        };

        for (let i = 0; i < itemRows.length; i++) {
            const item = itemRows.at(i) as FormGroup;
            myPacks.push({
                amount: item.controls.Nr.value === 0 ? '' : item.controls.Nr.value,
                type: item.controls.Pachet.value === 0 ? '' : item.controls.Pachet.value,
                length: item.controls.Lungime.value === 0 ? '' : item.controls.Lungime.value,
                width: item.controls.Latime.value === 0 ? '' : item.controls.Latime.value,
                height: item.controls.Inaltime.value === 0 ? '' : item.controls.Inaltime.value,
                weight: item.controls.Greutate.value === 0 ? '' : item.controls.Greutate.value,
            });
            if (cost.collectingCounty !== '' && cost.collectingLocality !== '' && cost.deliveryCounty !== '' && cost.deliveryLocality !== ''
                && item.controls.Nr.value !== '' && item.controls.Nr.value !== null && item.controls.Pachet.value !== ''
                && item.controls.Pachet.value !== null
                && item.controls.Lungime.value !== '' && item.controls.Lungime.value !== null &&
                item.controls.Latime.value !== '' && item.controls.Latime.value !== null &&
                item.controls.Inaltime.value !== '' && item.controls.Inaltime.value !== null
                && item.controls.Greutate.value !== '' && item.controls.Greutate.value !== null) {
                this.verif = true;
            }
        }
        const jsonValue = JSON.stringify(myPacks);
        const valueFromJson = JSON.parse(jsonValue);
        const success = (((((valueFromJson || {}).resReturn || {}).result || {}).gamingdata || {}).original || {}).success;
        this.router.navigate([`${apiName}`], {
            state: {
                cost: this.cost,
                cCounty: this.destination.controls.collectingCounty.value,
                cLocality: this.destination.controls.collectingLocality.value,
                dCounty: this.destination.controls.deliveryCounty.value,
                dLocality: this.destination.controls.deliveryLocality.value,
                detailsPack: jsonValue
            }
        });
    }

    goToPageIntern(apiName: string, total: string, type: string): void {
        const myPacks: Packages[] = [];
        const itemRows = this.packageInternational.controls.itemRows as FormArray;

        const cost = {
            countryCollecting: this.international.controls.countryCollecting.value,
            countryDestination: this.international.controls.countryDestination.value,
        };

        for (let i = 0; i < itemRows.length; i++) {
            const item = itemRows.at(i) as FormGroup;
            myPacks.push({
                amount: item.controls.Nr.value === 0 ? '' : item.controls.Nr.value,
                type: item.controls.Pachet.value === 0 ? '' : item.controls.Pachet.value,
                length: item.controls.Lungime.value === 0 ? '' : item.controls.Lungime.value,
                width: item.controls.Latime.value === 0 ? '' : item.controls.Latime.value,
                height: item.controls.Inaltime.value === 0 ? '' : item.controls.Inaltime.value,
                weight: item.controls.Greutate.value === 0 ? '' : item.controls.Greutate.value,
            });
            if (cost.countryCollecting !== '' && cost.countryDestination !== ''
                && item.controls.Nr.value !== '' && item.controls.Nr.value !== null && item.controls.Pachet.value !== ''
                && item.controls.Pachet.value !== null
                && item.controls.Lungime.value !== '' && item.controls.Lungime.value !== null &&
                item.controls.Latime.value !== '' && item.controls.Latime.value !== null &&
                item.controls.Inaltime.value !== '' && item.controls.Inaltime.value !== null
                && item.controls.Greutate.value !== '' && item.controls.Greutate.value !== null) {
                this.verif = true;
            }
        }
        const jsonValue = JSON.stringify(myPacks);
        const valueFromJson = JSON.parse(jsonValue);
        const success = (((((valueFromJson || {}).resReturn || {}).result || {}).gamingdata || {}).original || {}).success;

        this.router.navigate([`${apiName}`], {
            state: {
                cost: total, costType: type,
                cCountry: this.international.controls.countryCollecting.value,
                dCountry: this.international.controls.countryDestination.value,
                detailsPack: jsonValue
            }
        });
    }

    goToStatus(apiName: string): void {
        this.router.navigate([`${apiName}`]);
    }

    ngAfterViewInit(): void {
        if (this.route.snapshot.fragment) {
            document.getElementById('#' + this.route.snapshot.fragment).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    }

    mapView(content: any): void {
        this.modalService.open(content, {windowClass: 'dark-modal', size: 'lg', centered: true});
    }

}

